export default [
  {
    path: '/app/why-us',
    name: 'WhyUs',
    component: () => import('@/views/Admin/Why_us/Index.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Neden Propin?',
          active: true,
        },
      ],
      resource: 'ADMIN_WHY_US',
      action: 'read',
    },
  },
]
