import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/menus'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      title: null,
      target: '_self',
      ordering: null,
      status: '1',
      itemid: null,
      external_url: null,
      id_menu_types: null,
      id_menus: null,
      id_components: null,
    },
    id_components: null,
    selectComponent: null,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    id_components(state) {
      return state.id_components
    },
    selectComponent(state) {
      return state.selectComponent
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_ID_COMPONENTS(state, data) {
      state.id_components = data
    },
    SET_DATA_SELECT_COMPONENT(state, data) {
      state.selectComponent = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = {
          id: null,
          title: null,
          target: '_self',
          ordering: null,
          status: '1',
          itemid: null,
          external_url: null,
          id_menu_types: null,
          id_menus: null,
          id_components: null,
        }
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      axiosIns
        .post(`${ROOT_URL}/getMenuList`, params)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/getMenuItem`, { id })
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          commit('SET_DATA_ID_COMPONENTS', response.data.id_components)
          commit('SET_DATA_SELECT_COMPONENT', response.data.selectComponent)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    resetDataItem({ commit }) {
      commit('RESET_DATA_ITEM', true)
    },
  },
}
