import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
/* CORE */
import app from './routes/app'
/* ADMIN:CORE */
import users from './routes/Admin/Core/Users'
import userTypes from './routes/Admin/Core/User_types'
import smtpConfig from './routes/Admin/Core/Configs'
/* ADMIN:DEFINES */
import pressTypes from './routes/Admin/Defines/Press_types'
import estateConfigFeatures from './routes/Admin/Defines/Estate_config_features'
import estateConfigOptionGroups from './routes/Admin/Defines/Estate_config_option_groups'
import estateConfigPptions from './routes/Admin/Defines/Estate_config_options'
import webformParams from './routes/Admin/Defines/Webform_params'
import sectors from './routes/Admin/Defines/Sectors'
import countries from './routes/Admin/Defines/Countries'
import keywords from './routes/Admin/Defines/Keywords'
import cities from './routes/Admin/Defines/Cities'
import districts from './routes/Admin/Defines/Districts'
/* ADMIN:APP */
import about from './routes/Admin/App/About'
import aboutCounters from './routes/Admin/App/About_counters'
import blog from './routes/Admin/App/Blog'
import blogCategories from './routes/Admin/App/Blog_categories'
import bulletins from './routes/Admin/App/Bulletins'
import career from './routes/Admin/App/Career'
import carousel from './routes/Admin/App/Carousel'
import certificates from './routes/Admin/App/Certificates'
import companyProfile from './routes/Admin/App/Company_profile'
import components from './routes/Admin/App/Components'
import configs from './routes/Admin/App/Configs'
import contacts from './routes/Admin/App/Contacts'
import contents from './routes/Admin/App/Contents'
import corporateMaterials from './routes/Admin/App/Corporate_materials'
import deliveryMethods from './routes/Admin/App/Delivery_methods'
import esg from './routes/Admin/App/Esg'
import esgItems from './routes/Admin/App/Esg_items'
import estates from './routes/Admin/App/Estates'
import estateCategories from './routes/Admin/App/Estate_categories'
import estateConfigs from './routes/Admin/App/Estate_configs'
import memberships from './routes/Admin/App/Memberships'
import modLeads from './routes/Admin/App/Mod_leads'
import modOnlineMeeting from './routes/Admin/App/Mod_online_meeting'
import modReports from './routes/Admin/App/Mod_reports'
import news from './routes/Admin/App/News'
import notifications from './routes/Admin/App/Notifications'
import press from './routes/Admin/App/Press'
import pressReleases from './routes/Admin/App/Press_releases'
import references from './routes/Admin/App/References'
import reports from './routes/Admin/App/Reports'
import privateReports from './routes/Admin/App/Private_reports'
import segments from './routes/Admin/App/Segments'
import serviceCategories from './routes/Admin/App/Service_categories'
import services from './routes/Admin/App/Services'
import siteMenu from './routes/Admin/App/Site_menu'
import socialMedia from './routes/Admin/App/Socialmedia'
import testimonials from './routes/Admin/App/Testimonials'
import translate from './routes/Admin/App/Translate'
import whyUs from './routes/Admin/App/Why_us'
import whatsapp from './routes/Admin/App/Whatsapp'
import webformRequests from './routes/Admin/App/Webform_requests'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...app,
    ...configs,
    ...users,
    ...userTypes,
    ...siteMenu,
    ...about,
    ...aboutCounters,
    ...whyUs,
    ...blog,
    ...blogCategories,
    ...bulletins,
    ...references,
    ...career,
    ...carousel,
    ...contacts,
    ...components,
    ...certificates,
    ...companyProfile,
    ...contents,
    ...news,
    ...notifications,
    ...press,
    ...smtpConfig,
    ...socialMedia,
    ...translate,
    ...services,
    ...serviceCategories,
    ...corporateMaterials,
    ...deliveryMethods,
    ...esg,
    ...esgItems,
    ...estates,
    ...estateCategories,
    ...estateConfigs,
    ...memberships,
    ...modLeads,
    ...modOnlineMeeting,
    ...modReports,
    ...pressReleases,
    ...reports,
    ...privateReports,
    ...segments,
    ...testimonials,
    ...pressTypes,
    ...estateConfigFeatures,
    ...estateConfigOptionGroups,
    ...estateConfigPptions,
    ...webformParams,
    ...sectors,
    ...countries,
    ...keywords,
    ...cities,
    ...districts,
    ...whatsapp,
    ...webformRequests,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
