export default [
  {
    path: '/app/press-releases',
    name: 'PressReleases',
    component: () => import('@/views/Admin/Press_releases/Index.vue'),
    meta: {
      pageTitle: 'Basın Bültenleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PRESS_RELEASES',
      action: 'read',
    },
  },
  {
    path: '/app/press-releases/add',
    name: 'PressReleasesAdd',
    component: () => import('@/views/Admin/Press_releases/Add.vue'),
    meta: {
      pageTitle: 'Basın Bültenleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/press-releases',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PRESS_RELEASES',
      action: 'read',
    },
  },
  {
    path: '/app/press-releases/edit/:id',
    name: 'PressReleasesEdit',
    component: () => import('@/views/Admin/Press_releases/Edit.vue'),
    meta: {
      pageTitle: 'Basın Bültenleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/press-releases',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PRESS_RELEASES',
      action: 'read',
    },
  },
]
