export default [
  {
    path: '/app/mod-online-meeting',
    name: 'ModOnlineMeeting',
    component: () => import('@/views/Admin/Mod_online_meeting/Index.vue'),
    meta: {
      pageTitle: 'Modüller',
      breadcrumb: [
        {
          text: 'Online Toplantı',
          active: true,
        },
      ],
      resource: 'ADMIN_MODULES',
      action: 'read',
    },
  },
]
