export default [
  {
    path: '/defines/estate-config-options',
    name: 'EstateConfigOptions',
    component: () => import('@/views/Admin/Defines/Estate_config_options/Index.vue'),
    meta: {
      pageTitle: 'İlan Özellik Seçenekleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/estate-config-options/add',
    name: 'EstateConfigOptionAdd',
    component: () => import('@/views/Admin/Defines/Estate_config_options/Add.vue'),
    meta: {
      pageTitle: 'İlan Özellik Seçenekleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/estate-config-options',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/estate-config-options/edit/:id',
    name: 'EstateConfigOptionEdit',
    component: () => import('@/views/Admin/Defines/Estate_config_options/Edit.vue'),
    meta: {
      pageTitle: 'İlan Özellik Seçenekleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/estate-config-options',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
]
