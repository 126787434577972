export default [
  {
    path: '/defines/webform-params',
    name: 'WebformParams',
    component: () => import('@/views/Admin/Defines/Webform_params/Index.vue'),
    meta: {
      pageTitle: 'Webform Parametreleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/webform-params/add',
    name: 'WebformParamsAdd',
    component: () => import('@/views/Admin/Defines/Webform_params/Add.vue'),
    meta: {
      pageTitle: 'Webform Parametreleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/webform-params',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/webform-params/edit/:id',
    name: 'WebformParamsEdit',
    component: () => import('@/views/Admin/Defines/Webform_params/Edit.vue'),
    meta: {
      pageTitle: 'Webform Parametreleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/webform-params',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
]
