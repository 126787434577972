export default [
  {
    path: '/app/career',
    name: 'Career',
    component: () => import('@/views/Admin/Career/Index.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Kariyer',
          active: true,
        },
      ],
      resource: 'ADMIN_CAREER',
      action: 'read',
    },
  },
]
