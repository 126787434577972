export default [
  {
    path: '/app/private-reports',
    name: 'PrivateReports',
    component: () => import('@/views/Admin/Private_reports/Index.vue'),
    meta: {
      pageTitle: 'Özel Raporlar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
  {
    path: '/app/private-reports/add',
    name: 'PrivateReportAdd',
    component: () => import('@/views/Admin/Private_reports/Add.vue'),
    meta: {
      pageTitle: 'Özel Raporlar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/private-reports',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
  {
    path: '/app/private-reports/edit/:id',
    name: 'PrivateReportEdit',
    component: () => import('@/views/Admin/Private_reports/Edit.vue'),
    meta: {
      pageTitle: 'Özel Raporlar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/private-reports',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
]
