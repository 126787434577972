export default [
  {
    path: '/app/esg',
    name: 'Esg',
    component: () => import('@/views/Admin/Esg/Index.vue'),
    meta: {
      pageTitle: 'ESG',
      breadcrumb: [
        {
          text: 'Sayfa İçeriği',
          active: true,
        },
      ],
      resource: 'ADMIN_ESG',
      action: 'read',
    },
  },
]
