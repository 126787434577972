export default [
  {
    path: '/app/whatsapp',
    name: 'Whatsapp',
    component: () => import('@/views/Admin/Whatsapp/Index.vue'),
    meta: {
      pageTitle: 'Whatsapp',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_WHATSAPP',
      action: 'read',
    },
  },
  // {
  //   path: '/app/whatsapp/add',
  //   name: 'WhatsappAdd',
  //   component: () => import('@/views/Admin/Whatsapp/Add.vue'),
  //   meta: {
  //     pageTitle: 'Whatsapp',
  //     breadcrumb: [
  //       {
  //         text: 'Liste',
  //         to: '/app/whatsapp',
  //         active: false,
  //       },
  //       {
  //         text: 'Oluştur',
  //         active: true,
  //       },
  //     ],
  //     resource: 'ADMIN_WHATSAPP',
  //     action: 'read',
  //   },
  // },
  {
    path: '/app/whatsapp/edit/:id',
    name: 'WhatsappEdit',
    component: () => import('@/views/Admin/Whatsapp/Edit.vue'),
    meta: {
      pageTitle: 'Whatsapp',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/whatsapp',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_WHATSAPP',
      action: 'read',
    },
  },
]
