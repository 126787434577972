export default [
  {
    path: '/app/press',
    name: 'Press',
    component: () => import('@/views/Admin/Press/Index.vue'),
    meta: {
      pageTitle: 'Basında Biz',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PRESS',
      action: 'read',
    },
  },
  {
    path: '/app/press/add',
    name: 'PressAdd',
    component: () => import('@/views/Admin/Press/Add.vue'),
    meta: {
      pageTitle: 'Basında Biz',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/press',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PRESS',
      action: 'read',
    },
  },
  {
    path: '/app/press/edit/:id',
    name: 'PressEdit',
    component: () => import('@/views/Admin/Press/Edit.vue'),
    meta: {
      pageTitle: 'Basında Biz',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/press',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PRESS',
      action: 'read',
    },
  },
]
