export default [
  {
    path: '/app/segments',
    name: 'Segments',
    component: () => import('@/views/Admin/Segments/Index.vue'),
    meta: {
      pageTitle: 'Segmentler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICES',
      action: 'read',
    },
  },
  {
    path: '/app/segments/add',
    name: 'SegmentAdd',
    component: () => import('@/views/Admin/Segments/Add.vue'),
    meta: {
      pageTitle: 'Segmentler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/segments',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICES',
      action: 'read',
    },
  },
  {
    path: '/app/segments/edit/:id',
    name: 'SegmentEdit',
    component: () => import('@/views/Admin/Segments/Edit.vue'),
    meta: {
      pageTitle: 'Segmentler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/segments',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICES',
      action: 'read',
    },
  },
]
