export default [
  {
    path: '/app/reports',
    name: 'Reports',
    component: () => import('@/views/Admin/Reports/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
  {
    path: '/app/reports/add',
    name: 'ReportAdd',
    component: () => import('@/views/Admin/Reports/Add.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/reports',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
  {
    path: '/app/reports/edit/:id',
    name: 'ReportEdit',
    component: () => import('@/views/Admin/Reports/Edit.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/reports',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
  {
    path: '/app/reports/content',
    name: 'ReportContent',
    component: () => import('@/views/Admin/Report_content/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Sayfa İçeriği',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
]
