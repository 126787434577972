import Vue from 'vue'
import Vuex from 'vuex'

/* Theme Core */
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
/* App Core */
import users from './Admin/Core/users'
import userTypes from './Admin/Core/user_types'
import smtpConfig from './Admin/Core/smtpconfig'
import abilities from './Admin/Core/abilities'
import cropperUploads from './Admin/Core/cropper_uploads'
import fileUpload from './Admin/Core/file_upload'
/* App Data */
import about from './Admin/App/about'
import aboutCounter from './Admin/App/about_counter'
import aboutCounterItems from './Admin/App/about_counter_items'
import blog from './Admin/App/blog'
import blogCategories from './Admin/App/blog_categories'
import bulletins from './Admin/App/bulletins'
import career from './Admin/App/career'
import carousel from './Admin/App/carousel'
import certificates from './Admin/App/certificates'
import companyProfile from './Admin/App/company_profile'
import components from './Admin/App/components'
import contacts from './Admin/App/contacts'
import contents from './Admin/App/contents'
import corporateMaterials from './Admin/App/corporate_materials'
import deliveryMethods from './Admin/App/delivery_methods'
import downloadRequests from './Admin/App/download_requests'
import esg from './Admin/App/esg'
import esgItems from './Admin/App/esg_items'
import estateCategories from './Admin/App/estate_categories'
import estates from './Admin/App/estates'
import estateConfigs from './Admin/App/estate_configs'
import memberships from './Admin/App/memberships'
import menus from './Admin/App/menus'
import modLeads from './Admin/App/mod_leads'
import modReports from './Admin/App/mod_reports'
import modOnlineMeeting from './Admin/App/mod_online_meeting'
import news from './Admin/App/news'
import notifications from './Admin/App/notifications'
import press from './Admin/App/press'
import pressReleases from './Admin/App/press_releases'
import references from './Admin/App/references'
import reportHighlights from './Admin/App/report_highlights'
import reports from './Admin/App/reports'
import reportContent from './Admin/App/report_content'
import reportContentItems from './Admin/App/report_content_items'
import privateReports from './Admin/App/private_reports'
import segments from './Admin/App/segments'
import services from './Admin/App/services'
import serviceCategories from './Admin/App/service_categories'
import serviceMedia from './Admin/App/service_media'
import socialMedia from './Admin/App/socialmedia'
import testimonials from './Admin/App/testimonials'
import siteConfig from './Admin/App/site_config'
import translate from './Admin/App/translate'
import whyUs from './Admin/App/why_us'
import whyUsItems from './Admin/App/why_us_items'
import whatsapp from './Admin/App/whatsapp'
import seoConfigs from './Admin/App/seo_configs'
import webformRequests from './Admin/App/webform_requests'
/* App Defines */
import pressTypes from './Admin/Defines/press_types'
import menuTypes from './Admin/Defines/menu_types'
import estateConfigFeatures from './Admin/Defines/estate_config_features'
import estateConfigOptionGroups from './Admin/Defines/estate_config_option_groups'
import estateConfigOptions from './Admin/Defines/estate_config_options'
import webforms from './Admin/Defines/webforms'
import webformParams from './Admin/Defines/webform_params'
import notificationTypes from './Admin/Defines/notification_types'
import sectors from './Admin/Defines/sectors'
import countries from './Admin/Defines/countries'
import keywords from './Admin/Defines/keywords'
import cities from './Admin/Defines/cities'
import districts from './Admin/Defines/districts'
import bulletinConfig from './Admin/Defines/bulletin_config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    userTypes,
    smtpConfig,
    abilities,
    cropperUploads,
    fileUpload,
    about,
    aboutCounter,
    aboutCounterItems,
    blog,
    blogCategories,
    bulletins,
    career,
    carousel,
    certificates,
    companyProfile,
    components,
    contacts,
    contents,
    corporateMaterials,
    deliveryMethods,
    downloadRequests,
    esg,
    esgItems,
    estateCategories,
    estates,
    estateConfigs,
    memberships,
    menus,
    modLeads,
    modReports,
    modOnlineMeeting,
    news,
    notifications,
    press,
    pressReleases,
    references,
    reportHighlights,
    reports,
    privateReports,
    reportContent,
    reportContentItems,
    segments,
    services,
    serviceCategories,
    serviceMedia,
    socialMedia,
    testimonials,
    siteConfig,
    translate,
    whyUs,
    whyUsItems,
    pressTypes,
    menuTypes,
    estateConfigFeatures,
    estateConfigOptionGroups,
    estateConfigOptions,
    webforms,
    webformParams,
    notificationTypes,
    sectors,
    countries,
    keywords,
    whatsapp,
    seoConfigs,
    webformRequests,
    cities,
    districts,
    bulletinConfig,
  },
  strict: process.env.DEV,
})
