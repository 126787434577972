export default [
  {
    path: '/app/testimonials',
    name: 'Testimonials',
    component: () => import('@/views/Admin/Testimonials/Index.vue'),
    meta: {
      pageTitle: 'Bizi Tercih Edenler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_TESTIMONIALS',
      action: 'read',
    },
  },
  {
    path: '/app/testimonials/add',
    name: 'TestimonialAdd',
    component: () => import('@/views/Admin/Testimonials/Add.vue'),
    meta: {
      pageTitle: 'Bizi Tercih Edenler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/testimonials',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_TESTIMONIALS',
      action: 'read',
    },
  },
  {
    path: '/app/testimonials/edit/:id',
    name: 'Testimonialdit',
    component: () => import('@/views/Admin/Testimonials/Edit.vue'),
    meta: {
      pageTitle: 'Bizi Tercih Edenler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/testimonials',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_TESTIMONIALS',
      action: 'read',
    },
  },
]
