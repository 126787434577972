export default [
  {
    path: '/app/about-counters',
    name: 'AboutCounters',
    component: () => import('@/views/Admin/About_counters/Index.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Deneyim',
          active: true,
        },
      ],
      resource: 'ADMIN_ABOUT_COUNTERS',
      action: 'read',
    },
  },
]
