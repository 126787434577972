export default [
  {
    path: '/defines/delivery-methods',
    name: 'DeliveryMethods',
    component: () => import('@/views/Admin/Defines/Delivery_methods/Index.vue'),
    meta: {
      pageTitle: 'Teslim Şekli',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/delivery-methods/add',
    name: 'DeliveryMethodAdd',
    component: () => import('@/views/Admin/Defines/Delivery_methods/Add.vue'),
    meta: {
      pageTitle: 'Teslim Şekli',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/delivery-methods',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/delivery-methods/edit/:id',
    name: 'DeliveryMethodEdit',
    component: () => import('@/views/Admin/Defines/Delivery_methods/Edit.vue'),
    meta: {
      pageTitle: 'Teslim Şekli',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/delivery-methods',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
]
