export default [
  {
    path: '/app/memberships',
    name: 'Memberships',
    component: () => import('@/views/Admin/Memberships/Index.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Üyelikler',
          active: true,
        },
      ],
      resource: 'ADMIN_MEMBERSHIPS',
      action: 'read',
    },
  },
  {
    path: '/app/memberships/add',
    name: 'MembershipsAdd',
    component: () => import('@/views/Admin/Memberships/Add.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Üyelikler',
          to: '/app/memberships',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_MEMBERSHIPS',
      action: 'read',
    },
  },
  {
    path: '/app/memberships/edit/:id',
    name: 'MembershipsEdit',
    component: () => import('@/views/Admin/Memberships/Edit.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Üyelikler',
          to: '/app/memberships',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_MEMBERSHIPS',
      action: 'read',
    },
  },
]
