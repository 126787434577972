export default [
  {
    path: '/app/webform_requests',
    name: 'WebformRequests',
    component: () => import('@/views/Admin/Webform_requests/Index.vue'),
    meta: {
      pageTitle: 'Web Formlar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_WEBFORMS',
      action: 'read',
    },
  },
  {
    path: '/app/download-requests',
    name: 'WebformDownloadRequests',
    component: () => import('@/views/Admin/Webform_requests/Index.vue'),
    meta: {
      pageTitle: 'Web Formlar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_WEBFORMS',
      action: 'read',
    },
  },
  {
    path: '/app/webform_requests/view/:id',
    name: 'WebformRequestView',
    component: () => import('@/views/Admin/Webform_requests/View.vue'),
    meta: {
      pageTitle: 'Web Formlar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/webform_requests',
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_WEBFORMS',
      action: 'read',
    },
  },
]
