export default [
  {
    path: '/app/mod-leads',
    name: 'ModLeads',
    component: () => import('@/views/Admin/Mod_leads/Index.vue'),
    meta: {
      pageTitle: 'Modüller',
      breadcrumb: [
        {
          text: 'Lead Form',
          active: true,
        },
      ],
      resource: 'ADMIN_MODULES',
      action: 'read',
    },
  },
]
