export default [
  {
    path: '/defines/districts',
    name: 'Districts',
    component: () => import('@/views/Admin/Defines/Districts/Index.vue'),
    meta: {
      pageTitle: 'İlçeler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/districts/add',
    name: 'DistrictsAdd',
    component: () => import('@/views/Admin/Defines/Districts/Add.vue'),
    meta: {
      pageTitle: 'İlçeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/districts',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/districts/edit/:id',
    name: 'DistrictsEdit',
    component: () => import('@/views/Admin/Defines/Districts/Edit.vue'),
    meta: {
      pageTitle: 'İlçeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/districts',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
]
