export default [
  {
    path: '/app/esg-items',
    name: 'EsgItems',
    component: () => import('@/views/Admin/Esg_items/Index.vue'),
    meta: {
      pageTitle: 'ESG',
      breadcrumb: [
        {
          text: 'ESG Öğeleri',
          active: true,
        },
      ],
      resource: 'ADMIN_ESG',
      action: 'read',
    },
  },
  {
    path: '/app/esg-items/add',
    name: 'EsgAdd',
    component: () => import('@/views/Admin/Esg_items/Add.vue'),
    meta: {
      pageTitle: 'ESG',
      breadcrumb: [
        {
          text: 'ESG Öğeleri',
          to: '/app/esg-items',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_ESG',
      action: 'read',
    },
  },
  {
    path: '/app/esg-items/edit/:id',
    name: 'EsgEdit',
    component: () => import('@/views/Admin/Esg_items/Edit.vue'),
    meta: {
      pageTitle: 'ESG',
      breadcrumb: [
        {
          text: 'ESG Öğeleri',
          to: '/app/esg-items',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_ESG',
      action: 'read',
    },
  },
]
