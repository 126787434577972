export default [
  {
    path: '/app/corporate-materials',
    name: 'CorporateMaterials',
    component: () => import('@/views/Admin/Corporate_materials/Index.vue'),
    meta: {
      pageTitle: 'Kurumsal Materyaller',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CORPORATE_MATERIALS',
      action: 'read',
    },
  },
  {
    path: '/app/corporate-materials/add',
    name: 'CorporateMaterialsAdd',
    component: () => import('@/views/Admin/Corporate_materials/Add.vue'),
    meta: {
      pageTitle: 'Kurumsal Materyaller',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/corporate-materials',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CORPORATE_MATERIALS',
      action: 'read',
    },
  },
  {
    path: '/app/corporate-materials/edit/:id',
    name: 'CorporateMaterialsEdit',
    component: () => import('@/views/Admin/Corporate_materials/Edit.vue'),
    meta: {
      pageTitle: 'Kurumsal Materyaller',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/corporate-materials',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CORPORATE_MATERIALS',
      action: 'read',
    },
  },
]
