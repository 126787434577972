export default [
  {
    path: '/defines/sectors',
    name: 'Sectors',
    component: () => import('@/views/Admin/Defines/Sectors/Index.vue'),
    meta: {
      pageTitle: 'Sektörler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/sectors/add',
    name: 'SectorsAdd',
    component: () => import('@/views/Admin/Defines/Sectors/Add.vue'),
    meta: {
      pageTitle: 'Sektörler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/sectors',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/defines/sectors/edit/:id',
    name: 'SectorsEdit',
    component: () => import('@/views/Admin/Defines/Sectors/Edit.vue'),
    meta: {
      pageTitle: 'Sektörler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/defines/sectors',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES',
      action: 'read',
    },
  },
]
