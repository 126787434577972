export default [
  {
    path: '/app/company-profile',
    name: 'CompanyProfile',
    component: () => import('@/views/Admin/Company_profile/Index.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Şirket Profili',
          active: true,
        },
      ],
      resource: 'ADMIN_COMPANY_PROFILE',
      action: 'read',
    },
  },
]
