import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/career'
export default {
  namespaced: true,
  state: {
    dataItem: {
      id: null,
      title: null,
      content: null,
      button: null,
      id_users: null,
      id_docs: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataSave = {
        id: null,
        title: null,
        id_users: null,
      }
    },
  },
  actions: {
    getDataItem({ commit }) {
      commit('RESET_DATA_ITEM')
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      axiosIns
        .post(`${ROOT_URL}/saveData`, postData)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
